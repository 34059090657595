import React, { Component } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

export default class Contact extends Component {

	render() {
		return (
			<Layout>
				      <Seo
        title="Thanks for getting in touch!"
        description="Our Customer Service team will respond to your inquiry within 1-3 business days."
      />


			
			<div className="static">
				<section id="static-no-hero">&nbsp;</section>
				<section id="static-page" className="page-pad">
					<div className="page">
	
						<div className="column-single">
							<h2>Your inquiry has been received</h2>

						<p> Thank you for contacting us. Our Customer Service team will respond to your inquiry within 1-3 business days. For regional entries and upcoming deadlines, please make sure to visit artandwriting.org/myregion for details.
						</p>
						</div>
						

					</div>
				</section>

			</div>
	
				
			</Layout>
	    )
	}
}